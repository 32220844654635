import React, { useEffect, useState } from 'react'
import PageConnexionClientPhone from './PageConnexionClientPhone'
import CreationComptePhone from './CreationComptePhone'
import ConnexionMdpOublierPhone from './ConnexionMdpOublierPhone'
import './connexionClientPhone.css'
import VerificationEmail from './VerificationEmail'
import SaisieConfirmeMdp from './SaisieConfirmeMdp'
import ChangementMdp from './ChangementMdp'
import RdvClientPhone from '../RdvClientPhone/RdvClientPhone'

const ConnexionClientPhone = ({setSuppressionFooter}) => { 

  const [affichePageConnexionClient, setAffichePageConnexionClient] = useState(true)
  const [afficheVerifMail, setAfficheVerifMail] = useState(false)  
  const [afficheSaisieConfirmeMpd, setAfficheSaisieConfirmeMpd] = useState(false)
  const [afficheCreationCompte, setAfficheCreationCompte] = useState(false)
  const [afficheMdpOublier, setAfficheMdpOublier] = useState(false)   
  const [afficheChangementMdp, setAfficheChangementMdp] = useState(false)  
  const [afficheRdvClient, setAfficheRdvClient] = useState(false)

  useEffect(()=>{
    setSuppressionFooter(true)
  },[])
                  
  return (
    <div className='container-compte-phone'>
    { affichePageConnexionClient && <PageConnexionClientPhone setAfficheVerifMail={setAfficheVerifMail} 
              setAffichePageConnexionClient={setAffichePageConnexionClient}  setAfficheMdpOublier={setAfficheMdpOublier} 
              setAfficheRdvClient={setAfficheRdvClient} setAfficheChangementMdp={setAfficheChangementMdp}/>}

    { afficheVerifMail && <VerificationEmail setAfficheVerifMail={setAfficheVerifMail} 
              setAffichePageConnexionClient={setAffichePageConnexionClient} setAfficheSaisieConfirmeMpd={setAfficheSaisieConfirmeMpd} />}

    { afficheSaisieConfirmeMpd && <SaisieConfirmeMdp setAfficheSaisieConfirmeMpd={setAfficheSaisieConfirmeMpd} 
              setAffichePageConnexionCliente={setAffichePageConnexionClient} setAfficheCreationCompte={setAfficheCreationCompte}/>}

    { afficheCreationCompte && <CreationComptePhone setAfficheCreationCompte={setAfficheCreationCompte} 
              setAffichePageConnexionClient={setAffichePageConnexionClient}/>}
              
    { afficheMdpOublier && <ConnexionMdpOublierPhone setAfficheMdpOublier={setAfficheMdpOublier} 
              setAffichePageConnexionClient={setAffichePageConnexionClient} setAfficheChangementMdp={setAfficheChangementMdp} />}
              
    { afficheChangementMdp && <ChangementMdp setAfficheChangementMdp={setAfficheChangementMdp} 
              setAffichePageConnexionClient={setAffichePageConnexionClient} />}
              
    { afficheRdvClient && <RdvClientPhone setAfficheRdvClient={setAfficheRdvClient} 
              setAffichePageConnexionClient={setAffichePageConnexionClient} />}

 </div>
  )
}

export default ConnexionClientPhone