
import React, { useEffect, useState } from 'react'
import { BrowserRouter, Route, Routes, useLocation } from 'react-router-dom'
import './appWebDesk.css'
import PresentationDesk from '../Composant/PresentationDesk';
import ServiceDesk from '../Composant/ServiceDesk';
import GalleryDesk from '../Composant/GalleryDesk';
import ContactDesk from '../Composant/ContactDesk';
import NavBarDesk from '../Composant/NavBarDesk';
import FooterDesk from '../Composant/FooterDesk';
import AboutDesk from '../Composant/AboutDesk';

const AppWebDesktop = () =>{
  const [etatGestion, setEtatGestion] = useState(false);
  
  
  return (
    <>
     <BrowserRouter>
      <div className="app-container-desk">

         <div className='app-container-navbar'>
           {!etatGestion && <NavBarDesk />}
         </div>
              
          <div className="content-desk">
                  <Routes>       
                      <Route path='/' element={<PresentationDesk />}/>
                      <Route path='/service' element={<ServiceDesk />}/>
                      <Route path='/gallery' element={<GalleryDesk />}/>
                      <Route path='/about' element={<AboutDesk/>}/>   
                      <Route path='/contact' element={<ContactDesk/>} />  
                      <Route path='/gestion' element={<ContactDesk />} /> 
                      {  /*    <Route path='/verifemail' element={<VericationMail/>} />
                      <Route path='/saisiemdp' element={<SaisieConfirmeMdp />} />    
                      <Route path='/creation' element={<CreationCompte/>} />  
                      <Route path='/mpd' element={<ConnexionMdpOublier />} />
                      <Route path='/pageclient' element={<PageCouranteClient />} /> */ }
                  </Routes>
          </div>

          <div className='app-footer-desk'>
            {!etatGestion && <FooterDesk />}
          </div>

      </div>
    </BrowserRouter>
    </>
  )
}


  
 

export default AppWebDesktop