import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import axios from 'axios';
import './calendrierClient.css';
import { useMonContexte } from '../MonContext';
import moment from 'moment';
import 'moment-timezone';
import { useNavigate } from 'react-router-dom';

const CalendrierClient = ({setAfficheCalendrier, setAffichePriseRdv}) => {
    const {adresseServeurBackEnd, connexionClient, setDateRdvClient } = useMonContexte();
  
    const [events, setEvents] = useState([]);
  
    const navigate = useNavigate();
  
    useEffect(() => {
      fetchEvents();
    }, [adresseServeurBackEnd]); // Inclure personnelId dans les dépendances
  
  
    const fetchEvents = async () => {
      setEvents([]);
      
      try {
        const response = await axios.get(`${adresseServeurBackEnd}/rendezvous`,{
          headers:{Authorization: `Bearer ${connexionClient.token}`}
        });
        const formattedEvents = response.data.map(event => ({
          ...event,
          id: event.id,
          title: 'Rendez-vous',
          start: moment(event.dateHeure).toISOString(),
          end: moment(event.heureFin).toISOString(),
          employeId: event.employeId,
          backgroundColor: event.employeCouleur,
          eventDisplay: 'auto',
        }));
        setEvents(formattedEvents);
        console.log(formattedEvents)
      } catch (error) {
        if (error.response && error.response.status === 403) {
          console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
          navigate('/connexionClient');
        } else {
        console.error('Erreur lors de la récupération des événements:', error);
      }
     }
    };
  
  
    const handleEventClick = ({ event }) => {
      console.log(event)
    };
  
  
    const handleDateClick = (arg) => {
      console.log('arg Date Click: ',arg)
      const dateStr = moment(arg.dateStr).tz('Etc/UTC').format();
      setAfficheCalendrier(false);
      setAffichePriseRdv(true)
      setDateRdvClient(dateStr)
    }
  
   
  
    return (
      <div className="calendar-container">
       <FullCalendar
          hiddenDays={[0]}
          locale={frLocale}
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView='timeGridWeek'
          headerToolbar={{
            left: 'dayGridMonth,timeGridWeek,timeGridDay',
            center: 'title',
            right: 'prev,next today'
          }}
          events={events}
          eventClick={handleEventClick}
          dateClick={handleDateClick}
          timeZone="local"
          slotMinTime="08:00:00"  
          slotMaxTime="19:00:00"
        />
        
      </div>
    );
}

export default CalendrierClient