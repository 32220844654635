
import React, { useState } from 'react';
import { useMonContexte } from '../MonContext';
import axios from 'axios';
import './connexionClientPhone.css'

const ConnexionMdpOublierPhone = ({setAfficheMdpOublier, setAffichePageConnexionClient, setAfficheChangementMdp}) => {
  
  const {adresseServeurBackEnd, setConnexionClient} =  useMonContexte();

    const [email, setEmail] = useState('');
    const [code, setCode] = useState('');
    const [verificationCode, setVerificationCode] = useState(null);
    const [info, setInfo] = useState('');
    const [basculeEmailCode, setBasculeEmailCode] = useState(true);

    const handleSubmitEmail = async (e) => {
        e.preventDefault();
        let problemeServeur = false;
      if (!email) {
          setInfo("Veuillez entrer votre email.");
          console.log("Veuillez entrer votre email.")
          return;
      }
      let checkEmailValide = false
      try {
          const response = await axios.post(`${adresseServeurBackEnd}/checkemail`, {email });
          checkEmailValide = response.data.success;
          setConnexionClient(response.data.client[0])
          console.log(response.data.client[0])
      } catch (error) {
        console.log('Erreur lors du check de l\'email')
            console.error('Erreur lors du check de l\'email', error);          
            checkEmailValide = true;
            problemeServeur = true;
      } 
      if (!checkEmailValide) {
            setBasculeEmailCode(false)
            setCode('')
            try {
                const response = await axios.post(`${adresseServeurBackEnd}/send-email`, {email});
                setVerificationCode(response.data.verificationCode); // Sauvegarde du code pour la comparaison
                setInfo('Verifiez votre mail, un code à 6 chiffres vous a été envoyé');
                console.log(response.data.verificationCode)
            } catch (error) {
                console.error('Erreur lors de l\'envoi de l\'email', error);
            }     
      } else {
        problemeServeur? setInfo('Erreur serveur, impossible de vérifier l\'email') : setInfo('Votre email n\'existe pas, utiliser une adresse mail valide ou Cliquez sur Annuler pour créer un compte')
      }           
  };

  
  const handleVerifyCode = () => {       
      if (!code) {  // Vérification manuelle si le champ code est vide
          setInfo("Veuillez entrer votre code.");
        return;
      }
      if (parseInt(code) === verificationCode) {
          handleModifieMdp();
      } else {
          setInfo('Code de vérification incorrect.');
      }
  };

    const handleChangeEmail = (event) =>{
        setEmail(event);
        setInfo('');
    }

    const handleChangeCode = (event) =>{
        setCode(event);
        setInfo('');
    }

    const handleAnnuler = () =>{
        setAffichePageConnexionClient(true);
        setAfficheMdpOublier(false)
    }

    const handleRenvoiCode = () => {
        setBasculeEmailCode(true);
        setInfo('');
    };

    function handleModifieMdp(){        
        setAfficheMdpOublier(false)
        setAfficheChangementMdp(true)
    }

  return (
    <div className='container-mdp-oublier'>
      <h1>Mot de Passe oublié</h1>
      {basculeEmailCode && <div className='mdp-oublier-form'>
            <form onSubmit={handleSubmitEmail}>
                <label>Votre email:<br/>
                    <input
                      type="email"
                      placeholder="Entrez votre email"
                      value={email}
                      onChange={(e) => handleChangeEmail(e.target.value)}
                  />
                </label>
                <div className='btn-mdp-oublier'>
                  <button  type="submit" >Suivant</button>
                  <button  onClick={() => handleAnnuler()}>Annuler</button >
                </div>
            </form>
            <div className='information'>
                {info} 
            </div>
      </div>} 
      
      {!basculeEmailCode && <div className='bloc-verify'>
                <h3>Veuillez saisir code</h3>
                    <form className='verfi-code-form'>
                        <label>
                            <input 
                                type='text'                              
                                placeholder="Entrez votre code:"
                                value={code}
                                onChange={(e) => handleChangeCode(e.target.value)}
                                required
                            />
                        </label>
                        <div className='btn-mdp-oublier'>
                            <button type="button"  onClick={()=>handleVerifyCode()}>Suivant</button>
                            <button onClick={() =>handleRenvoiCode()}>Renvoyer le code</button>
                        </div>
                    </form>
                <div className='information'>{info}</div>
            </div>}   
    </div>
  )
}

export default ConnexionMdpOublierPhone