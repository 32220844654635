import React from 'react';
import insta from '../../assets/instagram.png'
import facebook from '../../assets/facebook.png'
import './contactDesk.css';

const Contact = () => {
    function FacebookLink() {
        return (
          <div>
            <a
              href="https://www.facebook.com/jeanMaheva" // Remplacez par l'URL de votre page Facebook
              target="_blank"
              rel="noopener noreferrer"
            >
            <div className='div-facebook'>
                        <img className='img-facebook' src={facebook} alt='imgFacebook'/>
            </div>
            </a>
          </div>
        );
      }

      function instagramLink() {
        return (
          <div>
            <a
              href="https://www.facebook.com/nick974" // Remplacez par l'URL de votre page Facebook
              target="_blank"
              rel="noopener noreferrer"
            >
             <div className='div-insta'>
                        <img className='img-insta' src={insta} alt='imgFacebook'/>
            </div>
            </a>
          </div>
        );
    }

  return (
    <div className="container-composant-desk">
        <div className='bloc-contact'>
            <div className='card-contact'>
                <h2>Contactez-Nous</h2>
                <p> Avenue de Paris, 97400 Saint Denis</p>
                <p> La Réunion</p>
                <p> +262 96 00 00  /  +692 24 00 00 </p>
                <p> contact@maricourt.ovh</p>
                <div className="social-media">
                    {instagramLink()}
                    {FacebookLink()}
                </div>
            </div>
        </div>
    </div>
  );
};

export default Contact;
