import React, { useEffect } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import imgPose3 from '../../assets/img-pose3.jpg';
import imgPose2 from '../../assets/img-pedicure.jpg';
import imgPose1 from '../../assets/img-soin.jpg';
import gommage from '../../assets/gommage.jpg';
import imagePied from '../../assets/image-pied.jpg';
import epil3 from '../../assets/img-epil3.jpg';
import epilCir from '../../assets/img-epil-cir.jpg';
import imgPose4 from '../../assets/img-pose.jpg';
import './galleriePhone.css';
import useWindowSize from './UseWindowSize';

const GalleryPhone = ({setSuppressionFooter}) => {
    const size = useWindowSize();
  
    // Fonction pour calculer la largeur basée sur un aspect ratio de 16:9 (1.778)
    const calculateWidth = () => {
      const maxCarouselWidth = size.width * 0.90; // Par exemple, le carousel occupe 55% de la largeur
      const heightBasedWidth = 1.778 * (size.height - 180); // Largeur basée sur la hauteur
      return Math.min(maxCarouselWidth, heightBasedWidth); // Utilise la plus petite des deux pour ajuster
    };

    useEffect(()=>{
        setSuppressionFooter(false)
      },[]) 

    return (
      <div className="container-gallery-phone">
        <Carousel width={`${calculateWidth()}px`} showThumbs={false} >
          <div className='gallery-phone-img'>
            <img src={imgPose1} alt="imgPose" />
            <p className="legend">Legend 2</p>
          </div>
          <div className='gallery-phone-img'>
            <img src={imgPose2} alt="imgPose" />
            <p className="legend">Legend 3</p>
          </div>
          <div className='gallery-phone-img'>
            <img src={imgPose3} alt="imgPose" />
            <p className="legend">Legend 1</p>
          </div>
          <div className='gallery-phone-img'>
            <img src={gommage} alt="imgPose" />
            <p className="legend">Legend 2</p>
          </div>
          <div className='gallery-phone-img'>
            <img src={imagePied} alt="imgPose" />
            <p className="legend">Legend 3</p>
          </div>
          <div className='gallery-phone-img'>
            <img src={epil3} alt="imgPose" />
            <p className="legend">Legend 2</p>
          </div>
          <div className='gallery-phone-img'>
            <img src={epilCir} alt="imgPose" />
            <p className="legend">Legend 3</p>
          </div>
          <div className='gallery-phone-img'>
            <img src={imgPose4} alt="imgPose" />
            <p className="legend">Legend 3</p>
          </div>
        </Carousel>
      </div>
    );
}

export default GalleryPhone