import React from 'react'
import './footerDesk.css'
 

const FooterDesk = () => { 
    return (   
        <div className='contenu-footer-desk'>
                    Ô pays des Nail, l'élégance jusqu'au bout des ongles 
        </div>
    )
}

export default FooterDesk