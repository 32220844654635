import React, { useState } from 'react';
import { useMonContexte } from '../MonContext';
import axios from 'axios';
import './connexionClientPhone.css'
import { useNavigate } from 'react-router-dom';

const VerificationEmail = ({setAfficheVerifMail, setAffichePageConnexionClient, setAfficheSaisieConfirmeMpd}) => {

    const {adresseServeurBackEnd, emailNewClient, setEmailNewClient} =  useMonContexte();
    
    const [code, setCode] = useState('');
    const [verificationCode, setVerificationCode] = useState(null);
    const [info, setInfo] = useState('');
    const [basculeEmail, setBasculeEmail] = useState(true); //basculement entre saisie Email et saisie Code

    const navigate = useNavigate();
  
    const handleSubmitEmail = async () => {

        if (!emailNewClient) {
            setInfo("Veuillez entrer votre email.");
            return;
        }

        const email = emailNewClient;
        let checkEmailValide = false
        try {
            const response = await axios.post(`${adresseServeurBackEnd}/checkemail`, {email });
            checkEmailValide = response.data.success;
        } catch (error) {
            console.error('Erreur lors du check de l\'email', error);
        } 
        if (checkEmailValide) {
            setBasculeEmail(false);
            try {
            const response = await axios.post(`${adresseServeurBackEnd}/send-email`, {email});
                console.log(response.data);
                setVerificationCode(response.data.verificationCode); // Sauvegarde du code pour la comparaison
                setInfo('Votre email est valide, verifiez votre mail, un code à 6 chiffres vous a été envoyé');
                } catch (error) {
                    console.error('Erreur lors de l\'envoi de l\'email', error);
                }     
        } else {
            setInfo('Votre email existe déja, Cliquez sur Annuler pour vous connectez ou récupérer votre mot passe')
        }           
    };
  
    const handleVerifyCode = () => {       
        if (!code) {  // Vérification manuelle si le champ code est vide
            setInfo("Veuillez entrer votre code.");
          return;
        }
        if (parseInt(code) === verificationCode) {
            setAfficheSaisieConfirmeMpd(true)
            setAfficheVerifMail(false);
        } else {
            setInfo('Code de vérification incorrect.');
        }
    };

    const handleRenvoiCode = () => {
        setBasculeEmail(true);
        setInfo('');
    };

    const handleAnnule = () => {
        setAffichePageConnexionClient(true);
        setAfficheVerifMail(false);
    }
  

   
    
  return (
    <div className='verif-mail-phone'>        
           <h1 className='h1-creation-compte'>Création d'un compte</h1>
        <div className='connexion-form'>                 
            {basculeEmail && <div className='bloc-email'>
                <h3>Veuillez saisir votre email</h3>
                <form className='verfi-mail-form'>
                    <label>
                        <input
                            type="email"
                            placeholder="Entrez votre email"
                            value={emailNewClient}
                            onChange={(e) =>{setInfo(); setEmailNewClient(e.target.value)}}
                            required
                        />
                    </label>
                    <div className='btn-mdp-oublier'>
                        <button type="button"  onClick={()=>handleSubmitEmail()}>Suivant</button>
                        <p onClick={() => handleAnnule()}>Annuler</p>
                    </div>                    
                </form>
                <div className='information'>{info}</div>
            </div>}
            {!basculeEmail && <div className='bloc-verify'>
                <h3>Veuillez saisir code</h3>
                    <form className='verfi-code-form'>
                        <label>
                            <input 
                                type='text'                              
                                placeholder="Entrez votre code:"
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                required
                            />
                        </label>
                        <div className='btn-mdp-oublier'>
                            <button type="button"  onClick={()=>handleVerifyCode()}>Suivant</button>
                            <button onClick={() => handleRenvoiCode()}>Renvoyer le code</button>
                        </div>
                    </form>
                <div className='information'>{info}</div>
            </div>}
        </div>
        
    </div>
    )
}

export default VerificationEmail