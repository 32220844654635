import React from 'react'
import { NavLink} from "react-router-dom";
import './navBarDesk.css';
import cadenas from '../../assets/icons8-cadenas48.png' 

const NavBarDesk = () => {
  return (
     <div className='container-navbar'>
          <div className='navbar'>
              <ul>      
                  <li> 
                      <NavLink to='/' className={(nav) => (nav.isActive ? "nav-active" : "")}>Accueil</NavLink>
                  </li>
                  <li>
                      <NavLink to='/service' className={(nav) => (nav.isActive ? "nav-active" : "")}>Services</NavLink>
                  </li>
                  <li>
                      <NavLink to='/gallery' className={(nav) => (nav.isActive ? "nav-active" : "")}>Galerie</NavLink>
                  </li>
                  <li>
                      <NavLink to='/contact' className={(nav) => (nav.isActive ? "nav-active" : "")}>Contact</NavLink>
                  </li>
                  <li>
                      <NavLink to='/gestion' className={(nav) => (nav.isActive ? "nav-active" : "")}>
                          <img className='img-cadena-nav' src={cadenas} alt='img-cadena'/>
                      </NavLink>
                  </li> 
              </ul> 
          </div>
      </div>
  )
}

export default NavBarDesk