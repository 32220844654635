import React, { useEffect } from 'react'
import alyce from '../../assets/alyce2.png'
import imgPose3 from '../../assets/img-pose3.jpg'
import imgPose2 from '../../assets/img-pedicure.jpg'
import imgPoseLong from '../../assets/image-longue.png'
import './servicePhone.css';
import useWindowSize from './UseWindowSize';

const ServicePhone = ({setSuppressionFooter}) => {
    const size = useWindowSize()

    useEffect(()=>{
        setSuppressionFooter(false)
      },[]) 

  return (
    <div className='container-composant-phone'>
        <div className='service-container-phone '>
            <div className='service-container-img-logo'>
                <img src={alyce} alt='img-alice' />                
            </div>
            <div className='container-service-bloc1'>
                <div className='service-bloc1'>
                    <div className='service-bloc1-img-pose3'>
                        <img src={imgPose3} alt='img-pose'/>
                    </div>                    
                    <div className='service-bloc1-text1'>
                            <p >Ô Pays des Nails, chaque visite est une occasion de vous 
                            chouchouter et de sublimer vos ongles selon vos envies. Que vous recherchiez une manucure 
                            élégante, une pose d'ongles sophistiquée ou un nail art audacieux, Alyce sera 
                            là pour répondre à tous vos besoins. </p>                    
                    </div>  
                </div> 
            </div>

            <div className='container-service-bloc2'>
                <div className='service-bloc2'> 
                    <div className='service-bloc2-img-pose2'>
                        <img src={imgPose2} alt='img-pose'/>
                    </div>
                    <div className='service-bloc2-text2'>
                        <p>En plus de nos services de manucure et d'onglerie, nous 
                        proposons également des conseils personnalisés pour l'entretien de vos ongles et de 
                        votre peau, ainsi que des produits de haute qualité pour prolonger la beauté de votre 
                        manucure entre chaque visite.</p>
                    </div> 
                                       
                </div>              
            </div>
            
            <div className='service-bloc-img-pose-long'>
                    <img src={imgPoseLong} alt='img-pose-long'/>
            </div>
        </div>       
    </div>
  )
}

export default ServicePhone