import React from 'react';
import imgPose3 from '../../assets/img-pose3.jpg';
import imgPose2 from '../../assets/img-pedicure.jpg';
import imgPose1 from '../../assets/img-soin.jpg';
import gommage from '../../assets/gommage.jpg';
import imagePied from '../../assets/image-pied.jpg';
import epil3 from '../../assets/img-epil3.jpg';
import epilCir from '../../assets/img-epil-cir.jpg';
import imgPose4 from '../../assets/img-pose.jpg';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './gallerieDesk.css';
import useWindowSize from './UseWindowSize';

const GalleryDesk = () => {
  const size = useWindowSize();

  // Fonction pour calculer la largeur basée sur un aspect ratio de 16:9 (1.778)
  const calculateWidth = () => {
    const maxCarouselWidth = size.width * 0.55; // Par exemple, le carousel occupe 55% de la largeur
    const heightBasedWidth = 1.778 * (size.height - 180); // Largeur basée sur la hauteur
    return Math.min(maxCarouselWidth, heightBasedWidth); // Utilise la plus petite des deux pour ajuster
  };

  return (
    <div className="container-composant-desk">
      <Carousel width={`${calculateWidth()}px`} showThumbs={false}>
        <div>
          <img src={imgPose1} alt="imgPose" />
          <p className="legend">Legend 2</p>
        </div>
        <div>
          <img src={imgPose2} alt="imgPose" />
          <p className="legend">Legend 3</p>
        </div>
        <div>
          <img src={imgPose3} alt="imgPose" />
          <p className="legend">Legend 1</p>
        </div>
        <div>
          <img src={gommage} alt="imgPose" />
          <p className="legend">Legend 2</p>
        </div>
        <div>
          <img src={imagePied} alt="imgPose" />
          <p className="legend">Legend 3</p>
        </div>
        <div>
          <img src={epil3} alt="imgPose" />
          <p className="legend">Legend 2</p>
        </div>
        <div>
          <img src={epilCir} alt="imgPose" />
          <p className="legend">Legend 3</p>
        </div>
        <div>
          <img src={imgPose4} alt="imgPose" />
          <p className="legend">Legend 3</p>
        </div>
      </Carousel>
    </div>
  );
};

export default GalleryDesk;




/*
import React, { useEffect } from 'react'
import imgPose3 from '../assets/img-pose3.jpg'
import imgPose2 from '../assets/img-pedicure.jpg'
import imgPose1 from '../assets/img-soin.jpg'
import gommage from '../assets/gommage.jpg'
import imagePied from '../assets/image-pied.jpg'
import epil3 from '../assets/img-epil3.jpg'
import epilCir from '../assets/img-epil-cir.jpg'
import imgPose4 from '../assets/img-pose.jpg'
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import './gallerieDesk.css'
import useWindowSize from '../UseWindowSize';

const GalleryDesk = () => {
  const size = useWindowSize()
  console.log(size.height)

  function calculateLeftMargin() {
    const divWidth = 1.778*(size.height-200);
    if (divWidth > size.width) {
        console.error("La largeur de la div ne peut pas être supérieure à la largeur de l'écran.");
        return 0;
    }    
    // Calculer la marge gauche pour centrer la div
    const leftMargin = (size.width - divWidth) / 2;    
    return leftMargin;
}

  function calculatePercentWidth(){
    const pourcent = parseInt(((1.778*(size.height-200))/size.width)*100)
    return (`${pourcent}%`)
  }


  useEffect(() =>{
        console.log(calculatePercentWidth());
  }, [])

  return (
    <>
      <div className='container-composant' style={{paddingLeft:calculateLeftMargin()}}>
             <Carousel width={calculatePercentWidth()}  >                
                    <div>
                        <img src={imgPose1}   alt="imgPose" />
                        <p className="legend">Legend 2</p>
                    </div>
                    <div>
                        <img src={imgPose2}  alt="imgPose" />
                        <p className="legend">Legend 3</p>
                    </div>
                    <div>
                    <img src={imgPose3}  alt="imgPose" />
                        <p className="legend">Legend 1</p>
                    </div>
                    <div>
                    <img src={gommage} alt="imgPose" />
                        <p className="legend">Legend 2</p>
                    </div>
                    <div>
                    <img src={imagePied}  alt="imgPose" />
                        <p className="legend">Legend 3</p>
                    </div>

                    <div>
                    <img src={imgPose3} alt="imgPose" />
                        <p className="legend">Legend 1</p>
                    </div>
                    <div>
                    <img src={epil3}  alt="imgPose" />
                        <p className="legend">Legend 2</p>
                    </div>
                    <div>
                    <img src={epilCir}  alt="imgPose" />
                        <p className="legend">Legend 3</p>
                    </div>
                    <div>
                    <img src={imgPose4}  alt="imgPose" />
                        <p className="legend">Legend 3</p>
                    </div>
              </Carousel>      
          </div>
    </>
  )
}
export default GalleryDesk;

*/