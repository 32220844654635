
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './priseRdvByClient.css';
import { useMonContexte } from '../MonContext';
import 'moment/locale/fr';
import { useNavigate } from 'react-router-dom';

const PriseRdvByClient = ({setAfficheCalendrier, setAffichePriseRdv}) => {
    const { adresseServeurBackEnd, connexionClient, dateRdvClient, setDateRdvClient } = useMonContexte();
  
    const [choixPresta, setChoixPresta] = useState([]);
    const [prixTotal, setPrixTotal] = useState(0);
    const [dureeTotalePresta, setDureeTotalePresta] = useState(0);
    const [prestations, setPrestations] = useState([]);
    const [prestaId, setPrestaId] = useState([]);
    const [employesDisponibles, setEmployesDisponibles] = useState([]);
    const [formData, setFormData] = useState({ dateHeure: dateRdvClient, employeId: '', prestations: [] });
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const fetchPrestations = async () => {
        console.log(connexionClient)
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/prestations`, {
                headers: { Authorization: `Bearer ${connexionClient.token}` }
            });
            setPrestations(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/connexionClient');
            } else {
                console.error('Erreur lors de la récupération des prestations:', error);
            }
        }
    };

    const fetchDisponibiliteEmploye = async () => {
        try {
            const response = await axios.get(`${adresseServeurBackEnd}/disponibilite`, {
                headers: { Authorization: `Bearer ${connexionClient.token}` },
                params: {
                    dateHeure: formData.dateHeure,
                    duree: dureeTotalePresta
                }
            });
            setEmployesDisponibles(response.data);
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/connexionClient');
            } else {
                console.error('Erreur lors de la récupération des disponibilites:', error);
            }
        }
    };

    useEffect(() => {
        fetchPrestations();
        console.log(connexionClient)
    }, []);

    useEffect(() => {
        if (dureeTotalePresta > 0 && formData.dateHeure) {
            fetchDisponibiliteEmploye();
        }
    }, [dureeTotalePresta, formData.dateHeure]);

  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setError('');
      if (name === 'dateHeure') {
        const dateHeure = new Date(value);
        setFormData({ ...formData, [name]: dateHeure });
      } else {
        setFormData({ ...formData, [name]: value });
      }
    };
  
    const handleClick = (prestation) => {
      setError('');
      const prestationData = {
        id: prestation.id,
        nom: prestation.nom,
        duree: prestation.duree,
        prix: prestation.prix
      };
      const nouvelleDureeTotale = dureeTotalePresta + prestation.duree;
      setDureeTotalePresta(nouvelleDureeTotale);
      setChoixPresta([...choixPresta, prestationData]);
      setPrixTotal(prixTotal + parseFloat(prestation.prix));
      const nouveauxIdsPrestations = [...prestaId, prestation.id];
      setPrestaId(nouveauxIdsPrestations);
      const dateHeure = new Date(formData.dateHeure);
      const heureFin = new Date(dateHeure.getTime() + nouvelleDureeTotale * 60000);
      setFormData({
        ...formData,
        heureFin: formatDateTimeLocal(heureFin),
        prestations: nouveauxIdsPrestations,
        status: 'Scheduled'
      })
    }
  

    const handlePrestationChange = (e) => {
        // Récupère les options sélectionnées
        const selectedOptions = Array.from(e.target.selectedOptions);
        
        // Calcule les nouvelles valeurs de choixPresta, prixTotal, et dureeTotalePresta
        const newChoixPresta = selectedOptions.map(option => {
            const prestation = prestations.find(p => p.id === parseInt(option.value));
            return {
                id: prestation.id,
                nom: prestation.nom,
                duree: prestation.duree,
                prix: prestation.prix
            };
        });
    
        // Calcule la nouvelle durée totale et le prix total
        const nouvelleDureeTotale = newChoixPresta.reduce((total, prestation) => total + prestation.duree, 0);
        const nouveauPrixTotal = newChoixPresta.reduce((total, prestation) => total + parseFloat(prestation.prix), 0);
    
        // Met à jour les états
        setChoixPresta(newChoixPresta);
        setDureeTotalePresta(nouvelleDureeTotale);
        setPrixTotal(nouveauPrixTotal);
        setPrestaId(newChoixPresta.map(prestation => prestation.id));
    
        // Met à jour formData pour inclure les prestations sélectionnées
        setFormData({
            ...formData,
            prestations: newChoixPresta.map(prestation => prestation.id)
        });
    };
    
    useEffect(() => {
      const dateHeure = new Date(formData.dateHeure);
      const heureFin = new Date(dateHeure.getTime() + dureeTotalePresta * 60000);
      setFormData({
        ...formData,
        heureFin: formatDateTimeLocal(heureFin),
        prestations: prestaId
      });
    }, [prestaId, dureeTotalePresta]);
  
    const handleClickClear = () => {
      setError('');
      setDureeTotalePresta(0);
      setPrixTotal(0);
      setChoixPresta([]);
      setPrestaId([]);
    };
  
    const handleAnnule = () => {
        setAfficheCalendrier(true);
        setAffichePriseRdv(false)
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log('connexionClient.id= ', connexionClient.id)
        try {
          const formattedData = {
              ...formData,
              clientId: connexionClient.id,
              dateHeure: new Date(formData.dateHeure).toISOString().slice(0, 19).replace('T', ' '),
              heureFin: new Date(formData.heureFin).toISOString().slice(0, 19).replace('T', ' '),
          };
        console.log(formattedData)
        
            const response = await axios.post(`${adresseServeurBackEnd}/rendezvous`, formattedData, {
                headers: {
                    Authorization: `Bearer ${connexionClient.token}`,
                    'Content-Type': 'application/json'
                }
            });
            console.log('Rendez-vous créé:', response.data);
            setAfficheCalendrier(true);
            setAffichePriseRdv(false)
        } catch (error) {
            if (error.response && error.response.status === 403) {
                console.error('Token invalide ou expiré. Redirection vers la page de connexion.');
                navigate('/connexionClient');
            } else {
                console.error('Erreur lors de la création du rendez-vous:', error);
                setError('Erreur lors de la création du rendez-vous');
            }
        }
    };

    const formatDateTimeLocal = (date) => {
        const d = new Date(date);
        return `${d.getFullYear()}-${String(d.getMonth() + 1).padStart(2, '0')}-${String(d.getDate()).padStart(2, '0')}T${String(d.getHours()).padStart(2, '0')}:${String(d.getMinutes()).padStart(2, '0')}`;
    };

    return (
        <div className='container-priserdv-phone'>
        <div className="saisie-rdv-container-phone">
            <h2>Ajouter un Rendez-vous pour</h2>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            
            <form onSubmit={handleSubmit}>
                <div className='bloc-identité-rdv'>
                    <div className="client-info">
                        <strong>{`${connexionClient.prenom} ${connexionClient.nom}`}</strong>
                    </div>         
                    <div className="client-info">
                        Tel:<strong> {connexionClient.telephone}</strong>
                    </div> 
                    <div className="client-info">
                        mail:<strong> {connexionClient.mail}</strong>
                    </div>       
                </div>

                <label>
                    Date et Heure:
                    <input
                        type="datetime-local"
                        name="dateHeure"
                        value={formatDateTimeLocal(formData.dateHeure)}
                        onChange={handleInputChange}
                        required
                    />
                </label>
                
                <div className='bloc-prestation'>
                    <p>Choisissez vos prestations:</p>
                    <div className='label-presta-choix'>
                        <label className='label-presta'>              
                           {/* <select name="prestations" multiple value={prestations.map(p => p.id)} readOnly>
                                {prestations.map((prestation) => (
                                    <option key={prestation.id} value={prestation.id} onClick={() => handleClick(prestation)}>
                                        {prestation.nom}
                                    </option>
                                ))}
                            </select>*/}

                                <select name="prestations" multiple onChange={handlePrestationChange}>
                                {prestations.map(prestation => (
                                    <option key={prestation.id} value={prestation.id}>
                                    {prestation.nom}
                                    </option>
                                ))}
                                </select>


                        </label>
                        <div className='choix-presta'>
                            <div className='choix-ul-li'>
                                <ul>
                                    {choixPresta.map((choix, index) => (
                                        <li key={index}>
                                            {choix.nom} {choix.duree} min
                                        </li>
                                    ))}
                                </ul>  
                            </div>
                        </div> 
                        <div className='choix-totaux'>
                            Prix total: {prixTotal}€ <br/> Durée total: {dureeTotalePresta} min
                        </div>
                    </div>
                    <div className='btn-choix-presta'>
                        <button type="button" onClick={handleClickClear}>Clear</button>
                    </div>
                </div>

                <div className='label-heure-fin'>
                    Heure de Fin:
                    <input
                        type="datetime-local"
                        name="heureFin"
                        value={formatDateTimeLocal(new Date(new Date(formData.dateHeure).getTime() + dureeTotalePresta * 60000))}
                        readOnly
                    />
                </div>
                <label className='label-select-employe'>
                    Employé:
                    <select
                        name="employeId"
                        value={formData.employeId}
                        onChange={handleInputChange}
                        required
                    >
                        <option value="">Sélectionner un employé</option>
                        {employesDisponibles.map((employe) => (
                            <option key={employe.id} value={employe.id}>
                                {employe.prenom}
                            </option>
                        ))}
                    </select>
                </label>
                {employesDisponibles.length > 0 && <div className="btn-form-rdv-phone">
                <button type="submit">Enregistrer</button> 
                <button type="button" onClick={handleAnnule}>Annuler</button> 
                </div>}
                {employesDisponibles.length === 0 && <div className="info-form-rdv-phone">
                    <p>Attention! il n'y a pas d'employé disponible.
                    Veuillez sélectionnées une prestation ou modifier la Date et Heure du Rdv</p>
                </div>}
            </form>
        </div>
        </div>
    );
};

export default PriseRdvByClient