import React, { useState } from 'react'
import CalendrierClient from './CalendrierClient'
import PriseRdvByClient from './PriseRdvByClient'

const RdvClientPhone = ({setAfficheRdvClient, setAffichePageConnexionClient}) => {

  const [afficheCalendrier, setAfficheCalendrier] = useState(true)
  const [affichePriseRdv, setAffichePriseRdv] = useState(false)  
  const [afficheSaisieConfirmeMpd, setAfficheSaisieConfirmeMpd] = useState(false)
  const [afficheCreationCompte, setAfficheCreationCompte] = useState(false) 

  return (
    <div>
      {afficheCalendrier && <CalendrierClient setAfficheCalendrier={setAfficheCalendrier} setAffichePriseRdv={setAffichePriseRdv} />}
      {affichePriseRdv && <PriseRdvByClient setAfficheCalendrier={setAfficheCalendrier} setAffichePriseRdv={setAffichePriseRdv} />}
    </div>
  )
}

export default RdvClientPhone