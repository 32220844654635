import React, { useState } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import './appWebPhone.css'
import PresentationPhone from '../Composant/PresentationPhone'
import NavBarPhone from '../Composant/NavBarPhone'
import FooterPhone from '../Composant/FooterPhone'
import AboutDesk from '../Composant/AboutDesk'
import ServicePhone from '../Composant/ServicePhone'
import GalleryPhone from '../Composant/GalleryPhone'
import ContactPhone from '../Composant/ContactPhone'
import ConnexionClientPhone from '../../ConnexionClientPhone/ConnexionClientPhone'

const AppWebPhone = () => {

  const [suppressionFooter, setSuppressionFooter] = useState(false);

  return (
    <>
      <BrowserRouter>
      <div className="app-container-phone">
        <NavBarPhone />
            <div className="content-phone">
                <Routes>       
                    <Route path='/' element={<PresentationPhone setSuppressionFooter={setSuppressionFooter} />} />
                    <Route path='/service' element={<ServicePhone setSuppressionFooter={setSuppressionFooter} />} />
                    <Route path='/gallery' element={<GalleryPhone setSuppressionFooter={setSuppressionFooter} />}/>
                    <Route path='/about' element={<AboutDesk setSuppressionFooter={setSuppressionFooter} />} />   
                    <Route path='/contact' element={<ContactPhone setSuppressionFooter={setSuppressionFooter} />} /> 
                    <Route path='/connexionClient' element={<ConnexionClientPhone setSuppressionFooter={setSuppressionFooter} />} /> 
                </Routes>
            </div>
            <div className='app-footer-phone'>
           {!suppressionFooter && <FooterPhone/>}
      </div> 
      </div>
    </BrowserRouter> 
    </>
  )
}

export default AppWebPhone