import React, { useEffect, useState } from 'react'
import { useMonContexte } from '../MonContext';
import './connexionClientPhone.css'
import { useNavigate } from 'react-router-dom';

const SaisieConfirmeMdp = ({setAfficheSaisieConfirmeMpd, setAffichePageConnexionClient, setAfficheCreationCompte}) => {

    const {mdpNewClient, setMpdNewClient} =  useMonContexte();

    const navigate = useNavigate();
    console.log(mdpNewClient)
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    const handlePasswordChange = (e) => {
        setMpdNewClient(e.target.value);
        setError('');
        setIsPasswordValid(false);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setError('');
        setIsPasswordValid(false);
    };

    const handleSubmit = (e) => {
        console.log(mdpNewClient)
        e.preventDefault();
        if (mdpNewClient === confirmPassword) {
            setAfficheSaisieConfirmeMpd(false);
            setAfficheCreationCompte(true);
        } else {
            setError('Les mots de passe ne correspondent pas.');
        }
    };

    const handleAnnuler = () => {
        setAfficheSaisieConfirmeMpd(false) 
        setAffichePageConnexionClient(true)
    }

    useEffect(() =>{
        setMpdNewClient('')
    },[])

    return (
        <div className='container-saisie-mdp'>
            <h2>Créer un mot de passe</h2>
            <form onSubmit={handleSubmit}>
                <div className='bloc-mdp' >
                    <label>
                        Mot de passe:<br/>
                        <input
                            type="password"
                            value={mdpNewClient}
                            onChange={handlePasswordChange}
                            required
                        />
                    </label>
                </div>
                <div className='bloc-mdp' >
                    <label>
                        Confirmez le mot de passe:<br/>
                        <input
                            type="password"
                            value={confirmPassword}
                            onChange={handleConfirmPasswordChange}
                            required
                        />
                    </label>
                </div>
                <div className='bloc-btn-saisie-mdp'>
                    <button type="submit">Valider</button>
                    <button onClick={()=> handleAnnuler()} >Annuler</button>
                </div>
            </form>
            {error && <p style={{ color: 'red' }}>{error}</p>}
            {isPasswordValid && <p style={{ color: 'green' }}>Les mots de passe correspondent!</p>}
        </div>
    );
};

export default SaisieConfirmeMdp