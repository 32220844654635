import React, { useState } from 'react'
import { useMonContexte } from '../MonContext';
import './connexionClientPhone.css'
import axios from 'axios';

const ChangementMdp = ({setAfficheChangementMdp, setAffichePageConnexionClient}) => {  
  
    const {adresseServeurBackEnd, connexionClient} =  useMonContexte();

    const [mdpNewClient, setMdpNewClient] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [error, setError] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);

    console.log(connexionClient)    

    const handlePasswordChange = (e) => {
        setMdpNewClient(e.target.value);
        setError('');
        setIsPasswordValid(false);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setError('');
        setIsPasswordValid(false);
    };
 
    const handleSubmit = async(e) => {
        console.log(mdpNewClient)
        console.log(connexionClient)
        e.preventDefault();
        const newPassword = mdpNewClient;
        const id = connexionClient.id;
        if (mdpNewClient === confirmPassword) {
            setIsPasswordValid(true);
            try {
                const response = await axios.post(`${adresseServeurBackEnd}/changeMdpClient`, {id, newPassword});
                alert('Votre mot de passe a bien été changé, Veuillez vous connecter')
                handleConnexion();
            } catch (error) {
                console.error('Erreur lors de la mise à jour du mot de passe', error);
            }
        } else {
            setError('Les mots de passe ne correspondent pas.');
        }
    };

    const handleConnexion = () => {
        setAfficheChangementMdp(false) 
        setAffichePageConnexionClient(true)
    }

    
 
return (
    <div className='container-saisie-mdp'>
        <h2>Changer votre mot de passe</h2>
        <form onSubmit={handleSubmit}>
            <div className='bloc-mdp' >
                <label>
                    Nouveau mot de passe:<br/>
                    <input
                        type="password"
                        value={mdpNewClient}
                        onChange={handlePasswordChange}
                        required
                    />
                </label>
            </div>
            <div className='bloc-mdp' >
                <label>
                    Confirmez le mot de passe:<br/>
                    <input
                        type="password"
                        value={confirmPassword}
                        onChange={handleConfirmPasswordChange}
                        required
                    />
                </label>
            </div>
            <div className='bloc-btn-saisie-mdp'>
                <button type="submit">Valider</button>
                <button onClick={()=> handleConnexion()} >Annuler</button>
            </div>
        </form>
        {error && <p style={{ color: 'red' }}>{error}</p>}
        {isPasswordValid && <p style={{ color: 'green' }}>Les mots de passe correspondent!</p>}
    </div>
);
}

export default ChangementMdp