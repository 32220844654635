
//Voici mon composant parent

import React, { useEffect, useState } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import menu from '../../assets/icons8-menu-48.png';
import './navBarPhone.css';

const NavBarPhone = () => {
  // État pour contrôler l'ouverture du menu burger
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation(); // Récupérer l'URL actuelle
  const [activeLink, setActiveLink] = useState("");

  // Fonction pour basculer l'état du menu burger
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    
    switch (location.pathname) {
      case "/":
        setActiveLink("Accueil");
        break;
      case "/service":
        setActiveLink("Services");
        break;
      case "/gallery":
        setActiveLink("Galerie");
        break;
      case "/contact":
        setActiveLink("Contact");
        break;
      default:
        setActiveLink("");
    }
  }, [location.pathname,]);

  


  return (

  <>

    <div className='container-navbuger'>     
          <div className='burger-navlink-active'>
             <p>{activeLink}</p>
          </div>
          <div className='burger-icon' onClick={toggleMenu}>
            <img src={menu} alt='img-menu' />
          </div>
      </div>

        { isOpen && <div className={'navbar-open'} onClick={()=> setIsOpen(false)}>
            <ul>
              <li>
                <NavLink to='/' className={(nav) => (nav.isActive ? "nav-active" : "nav-burg")}>Accueil</NavLink>
              </li>
              <li>
                <NavLink to='/service' className={(nav) => (nav.isActive ? "nav-active" : "nav-burg")}>Services</NavLink>
              </li>
              <li>
                <NavLink to='/gallery' className={(nav) => (nav.isActive ? "nav-active" : "nav-burg")}>Galerie</NavLink>
              </li>
              <li>
                <NavLink to='/contact' className={(nav) => (nav.isActive ? "nav-active" : "nav-burg")}>Contact</NavLink>
              </li>
              <li>
                <NavLink to='/connexionClient' className={(nav) => (nav.isActive ? "nav-active" : "nav-burg")}>Connexion</NavLink>
              </li>
            </ul>
          </div>}
  </>
  );
};

export default NavBarPhone

