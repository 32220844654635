
import React, { useEffect, useState } from 'react';
import { useMonContexte } from '../MonContext';
import axios from 'axios';
import './connexionClientPhone.css'
import { useNavigate } from 'react-router-dom';
import bcrypt from 'bcryptjs';

const CreationComptePhone = ({setAfficheCreationCompte, setAffichePageConnexionClient}) => {

    const {adresseServeurBackEnd, emailNewClient, mdpNewClient, setConnexionClient} =  useMonContexte();

    const ficheNull = { id: null, nom: '', prenom: '', dateNaissance: '', telephone: '', adresse: '', 
                        codePostal: '', ville: '', mail: emailNewClient }

    const [form, setForm] = useState(ficheNull);

    const navigate = useNavigate();
  
      
    const generateHashedPassword = async(password) => {
        try {
            const saltRounds = 10;
            const hashedPassword = await bcrypt.hash(password, saltRounds);
            console.log(hashedPassword)
            return hashedPassword;
        } catch (error) {
            console.error("Erreur lors du hachage du mot de passe:", error);
        }
    }

    const handleChange = (e) => {
      const { name, value } = e.target;
      setForm((prevForm) => ({ ...prevForm, [name]: value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        console.log(mdpNewClient)
        const motDePasse = await generateHashedPassword(mdpNewClient); 
        try {
            const formattedDate = new Date(form.dateNaissance).toISOString().split('T')[0]; // Format ISO 8601 YYYY-MM-DD
            const formData = { ...form, dateNaissance: formattedDate, motDePasse: motDePasse };
            console.log(formData)
            const response = await axios.post(`${adresseServeurBackEnd}/clientsOnline`, formData,{
            headers: {
                'Content-Type': 'application/json'
            }
            });
            alert('Vous devez vous connecter, pour accéder à votre compte')
            setAfficheCreationCompte(false); 
            setAffichePageConnexionClient(true);
            setForm(ficheNull);
            setConnexionClient(null)
        } catch (error) {        
            console.error('Erreur lors de l\'enregistrement du client', error);        
        }
    };
  
    const handleAnnuler = () => {
        setAfficheCreationCompte(false) 
        setAffichePageConnexionClient(true)
    }

    return (
        <div className='container-creation-compte'>
            <div className='bloc-creation-client'>
                <h1>Création d'un nouveau Client</h1>
                <form className="creation-compte-client-forme" onSubmit={handleSubmit}>
                    <label>Nom</label> <br/>
                    <input
                        type="text"
                        name="nom"
                        placeholder="Nom"
                        value={form.nom}
                        onChange={handleChange}
                        required
                        /> <br/>
                    <label>Prénom</label> <br/>
                    <input
                        type="text"
                        name="prenom"
                        placeholder="Prénom"
                        value={form.prenom}
                        onChange={handleChange}
                        /> <br/>
                    <label>Date de naissance</label> <br/>
                        <input id='date'
                        type="date"
                        name="dateNaissance"
                        placeholder="Date de Naissance"
                        value={form.dateNaissance}
                        onChange={handleChange}
                        /> <br/>
                    <label>Téléphone</label> <br/>
                        <input
                        type="text"
                        name="telephone"
                        placeholder="Téléphone"
                        value={form.telephone}
                        onChange={handleChange}
                        /> <br/>
                    <label>Adresse</label> <br/>
                        <input
                        type="text"
                        name="adresse"
                        placeholder="Adresse"
                        value={form.adresse}
                        onChange={handleChange}
                        /> <br/>
                    <label>Code Postal</label> <br/>
                        <input
                        type="text"
                        name="codePostal"
                        placeholder="Code Postal"
                        value={form.codePostal}
                        onChange={handleChange}
                        /> <br/>
                    <label>Ville</label> <br/>
                        <input
                        type="text"
                        name="ville"
                        placeholder="Ville"
                        value={form.ville}
                        onChange={handleChange}
                        /> <br/>
                    <label>Email: {emailNewClient}</label> 
                    <div className='btn-creation-client'>
                        <button type="submit">Ajouter</button>
                        <button onClick={()=> handleAnnuler()}>Annuler</button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default CreationComptePhone