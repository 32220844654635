import React, { useEffect } from 'react';
import insta from '../../assets/instagram.png'
import facebook from '../../assets/facebook.png'
import './contactPhone.css';


const ContactPhone = ({setSuppressionFooter}) => {

    function openFacebookPage() {
        const facebookAppUrl = "fb://profile/linfo.re/329398390091"; // Lien vers l'application Facebook
        const facebookWebUrl = "https://www.facebook.com/linfo.re/329398390091"; // Lien vers la page web
        let openedApp = false;
        // Essayez d'ouvrir l'application Facebook
        window.location.href = facebookAppUrl;
        setTimeout(() => {
            openedApp = true;
          }, 100);
        // Redirection de secours au cas où l'application n'est pas installée
        setTimeout(() => {
            if (!openedApp) {
                window.location.href = facebookWebUrl;
              }
        }, 500); // Attente avant de rediriger vers le navigateur
      }
    
    function FacebookLink() {
        return (
            <div className='div-facebook' onClick={() => openFacebookPage()}>
                        <img className='img-facebook' src={facebook} alt='imgFacebook'/>
            </div>
        );
      }

      function instagramLink() {
        return (
          <div>
            <a
              href="https://www.instagram.com/nick974" // Remplacez par l'URL de votre page Facebook
              target="_blank"
              rel="noopener noreferrer"
            >
             <div className='div-insta'>
                        <img className='img-insta' src={insta} alt='imgFacebook'/>
            </div>
            </a>
          </div>
        );
    }

    useEffect(()=>{
        setSuppressionFooter(false)
      },[]) 

    return (
      <div className="container-composant-phone">
          <div className='bloc-contact-phone'>
              <div className='card-contact-phone'>
                  <h2>Contactez-Nous</h2>
                  <p> Avenue de Paris, 97400 Saint Denis</p>
                  <p> La Réunion</p>
                  <p> +262 96 00 00  /  +692 24 00 00 </p>
                  <p> contact@maricourt.ovh</p>
                  <div className="social-media-phone">
                    {instagramLink()}
                    {FacebookLink()}
                  </div>
              </div>
          </div>
      </div>
    );
}

export default ContactPhone