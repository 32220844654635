import React, { useEffect, useState } from 'react'
import useWindowSize from './Composant/UseWindowSize';
import AppWebDesktop from './TypeEcran/AppWebDesktop';
import AppWebPhone from './TypeEcran/AppWebPhone';

const HomeWeb = () => { 
  
    const size = useWindowSize() 
    const [typeEcran, setTypeEcran] = useState(<AppWebDesktop/>)
  
    useEffect(() => {      
      if (size.width > 700) {
        setTypeEcran(<AppWebDesktop/>);   
      } else {
        setTypeEcran(<AppWebPhone/>);
      }
    }, [size.width]);
  
  return (
    <>
      {typeEcran}
    </>
  )
}

export default HomeWeb