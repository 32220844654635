import React, { useEffect, useState } from 'react'
import './App.css';
import MonContext from './MonContext';
import SiteWeb from './PointEntree/SiteWeb';
import AcceuilPersonnel from './PointEntree/AcceuilPersonnel';
import HomeWeb from './SiteWeb/HomeWeb';

function App() {

  const [affichageChoixEntree, setAffichageChoixEntreeAppli] = useState(<SiteWeb/>)
  const [valeurEntree, setValeurEntree] = useState(0)

  function choixEntree (valeur) {
    switch (valeur) {
      case  0 :
        setAffichageChoixEntreeAppli(<HomeWeb/>)
        break;
      case 1 :
        setAffichageChoixEntreeAppli(<AcceuilPersonnel/>)
         break;
      default:
        break;
    }
  }

  useEffect(() => {
    choixEntree (valeurEntree)
  },[valeurEntree])

  return ( 
    <MonContext>
      {affichageChoixEntree}  
    </MonContext>
  );
}
export default App;

