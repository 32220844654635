
import React, { useState } from 'react';
import axios from 'axios';
import { useMonContexte } from '../MonContext';
import './connexionClientPhone.css'

const PageConnexionClientPhone = ({setAfficheVerifMail, setAfficheMdpOublier, setAffichePageConnexionClient, setAfficheRdvClient,
                                    setAfficheChangementMdp}) => {

    
  const {adresseServeurBackEnd, setConnexionClient} =  useMonContexte();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');

  
  setConnexionClient(null)

  const handleSubmit = async(e) =>{
    e.preventDefault();
    console.log("Email:", email, "Mot de passe:", password);
    try {
          const response = await axios.post(`${adresseServeurBackEnd}/loginClient`, {email, password },
           );
           setConnexionClient(response.data.userData);
           if (response.data.valideMdp){
                fonctionConnexionRdv();
                setMessage(response.data.message);
            } else {
                alert('Vous devez changer votre mot de passe');
                setAfficheChangementMdp(true);
                setAffichePageConnexionClient(false);                     
            }
       } catch (error) {
           console.error('Erreur lors de l\'envoi de l\'email', error);
           setMessage('Email ou mot de passe incorrecte')
       } 

}

const fonctionVerifMail = () =>{
    setAfficheVerifMail(true)
    setAffichePageConnexionClient(false)
}

const fonctionOublieMdp = () =>{
    setAfficheMdpOublier(true);
    setAffichePageConnexionClient(false)
}

const fonctionConnexionRdv = () =>{
    setAfficheRdvClient(true);
    setAffichePageConnexionClient(false)
}

const fonctionModiferMdp = (valideMpd, message) =>{
    console.log('etat du mdp: ', valideMpd)
    if (!valideMpd){
        alert('Vous devez changer votre mot de passe');
        setAfficheChangementMdp(true);
        setAffichePageConnexionClient(false);
        console.log('le mdp doit être changer')
    } else {
        fonctionConnexionRdv();
        console.log('le mdp correct')        
    }
    setMessage(message);
}

return (
    <>      
       <div className='bloc-connexion-client'> 
            
                <div className='creation-compte-phone'>
                    <p onClick={()=>fonctionVerifMail()}>Créer un compte</p>
                </div>

                <div className='connexion-form-phone'>
                    <h2>Connectez Vous</h2>
                    <form className='formSelectClient' onSubmit={handleSubmit}>
                        <label>Votre email:<br/>
                            <input
                                type="email"                        
                                name="email"
                                placeholder="Entrez votre email"
                                value={email}
                                onChange={(e) => {setMessage(''); setEmail(e.target.value)}}
                            />
                        </label><br/>

                        <label>Votre mot de passe:<br/>
                            <input
                                type="password"
                                name="password"
                                placeholder="********"
                                value={password}
                                onChange={(e) => {setMessage(''); setPassword(e.target.value)}}
                            />
                        </label>
                        <div className='btn-mdp-oublier'>
                            <button type='submit'>Connexion</button>
                            <p onClick={()=>fonctionOublieMdp()}>Mot de passe oublié</p>
                        </div>
                    </form>
                </div>  

                <div className='bloc-message'>
                    <p style={{color:'red'}} >{message} </p> 
                </div>

        </div>
    </>
);
}

export default PageConnexionClientPhone