import React from 'react'
import './footerPhone.css'

const FooterPhone = () => {
  return (  
    <div className='contenu-footer-phone'>
                Ô pays des Nail, l'élégance jusqu'au bout des ongles 
    </div>
)
}

export default FooterPhone